import DataTable from 'datatables.net-dt';


import DefaultController from "../../defaultController";
import Utils from "../../../utils";


export default class WMSHome extends DefaultController {
    private datatable: any;
    private startButton: HTMLButtonElement | undefined

    async init() {
        this.startButton = document.querySelector("#wmsStartPicking") as HTMLButtonElement
        this.createTable()
        await super.init();
    }

    protected getEntityData(elem: any) {
        return null
    }

    bindListeners() {
        if (this.startButton) {
            this.startButton.addEventListener("click", async (e) => {
                const selectedRows = this.datatable.rows({selected: true}).data();
                const wmsPickList: any = {}
                Array.from(selectedRows).forEach((row: any) => {
                    Object.keys(row.wms.lineItems).forEach((lineItemId) => {
                        const lineItems = row.wms.lineItems[lineItemId]
                        lineItems.forEach((lineItem: any) => {
                            const warehouseLocation = lineItem.warehouse_location
                            const warehouseLocationPallet = lineItem.warehouse_location_pallet
                            if (!wmsPickList[warehouseLocation.id]) {
                                wmsPickList[warehouseLocation.id] = {
                                    name: warehouseLocation.name,
                                    items: {}
                                }
                            }
                            if (!wmsPickList[warehouseLocation.id].items[lineItem.product_id]) {
                                wmsPickList[warehouseLocation.id].items[lineItem.product_id] = []
                            }
                            wmsPickList[warehouseLocation.id].items[lineItem.product_id].push({
                                lineItemId: lineItemId,
                                orderId: row.id,
                                mapId: lineItem.map_id,
                                palletId: warehouseLocationPallet ? warehouseLocationPallet.id : null,
                                customerId: row.customer_id,
                                serial: lineItem.serial
                            })
                        })
                    })
                })
                this.startButton.setAttribute("disabled", "disabled")
                await this.startPicking(wmsPickList)
            })
        }
    }

    async startPicking(pickList: IWMSPickList) {
        const warehouseId = localStorage.getItem('tickware_wms_warehouse') as string
        const transactionRequest = await Utils.wms.startPicking(pickList, warehouseId);
        document.location.href = `/${(window as any).currentLocale}/wms/picking/${transactionRequest.transaction.uuid}`
    }

    createTable() {
        const warehouseId = localStorage.getItem('tickware_wms_warehouse')
        if (warehouseId) {
            this.datatable = new DataTable('.datatables-users', {
                initComplete: () => {
                    const tableElem = document.querySelector('.datatables-users') as HTMLElement
                    ((tableElem.closest(".card") as HTMLElement).querySelector(".loading-body") as HTMLElement).classList.add("d-none");
                    ((tableElem.closest(".card") as HTMLElement).querySelector(".card-datatable") as HTMLElement).classList.remove("d-none")
                },
                pageLength: 1000,
                serverSide: true,
                ajax: {
                    url: `/api/v1/erp/orders?status=open&warehouseId=${warehouseId}`,
                    dataType: 'json',
                    // @ts-ignore
                    data: (params: { length: any; start: any; draw: any; order: any; search: any; }) => {
                        return {
                            limit: params.length,
                            page: params.start / 1000 + 1,
                            draw: params.draw,
                            search: params.search ? params.search.value : null,
                            sort: params.order ? params.order[0] : []
                        };
                    },
                    // @ts-ignore
                    dataSrc: (data: any, params: any) => {
                        if (data.status === 200) {
                            data["recordsTotal"] = data.meta.total;
                            data["recordsFiltered"] = data.meta.total;
                            data["draw"] = data.meta.draw;
                        }
                        return data.data;
                    },
                    cache: true
                },
                columns: [
                    {data: 'uuid'},
                    {data: 'orderNumber'},
                    {data: 'uuid'},
                    {data: 'uuid'},
                    {data: 'orderNumber'},
                ],
                select: {
                    selector: 'td:first-child',
                    headerCheckbox: true,
                    style: 'multi'
                },
                order: [[1, 'desc']],
                columnDefs: [
                    {
                        targets: 0,
                        orderable: false,
                        render: DataTable.render.select(),
                    },
                    {
                        targets: 1,
                        render: (data: any, type: any, full: any, meta: any) => {
                            return `${data}`;
                        },
                    },
                    {
                        targets: 2,
                        render: (data: any, type: any, full: any, meta: any) => {
                            const company = `${full.orderCustomer[0].company ? full.orderCustomer[0].company : ""}`;
                            const location = `${full.deliveries[0].shippingOrderAddress[0] ? full.deliveries[0].shippingOrderAddress[0].city : null}`;
                            return `${company}, ${location || ''}`
                        },
                    },
                    {
                        targets: 3,
                        render: (data: any, type: any, full: any, meta: any) => {
                            let items = [];
                            Object.keys(full.wms.lineItems).forEach((lineItemId) => {
                                const lineItems = full.wms.lineItems[lineItemId]
                                const lineItemName = full.lineItems.filter((l: {
                                    id: string;
                                }) => l.id === lineItemId)[0].label
                                items.push(`${lineItems.length}x ${lineItemName}`)

                            })
                            return `${items.join("<br />")}`;
                        },
                    },
                    {
                        targets: 4,
                        render: (data: any, type: any, full: any, meta: any) => {
                            const d = new Date(Date.parse(full.orderDateTime))
                            return `${d.toLocaleString()}`;
                        },
                    },
                ],
                dom: 't' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info": `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: []
            });
            this.datatable.on('select deselect', (e: any) => {
                const selectedRows = this.datatable.rows({selected: true}).data();
                console.log("e", selectedRows)
                if (this.startButton) {
                    if (selectedRows.length === 0) {
                        this.startButton.innerHTML = `${Utils.translate('wms.messages.start_picking')}`
                        this.startButton.setAttribute("disabled", "disabled")
                    } else {
                        this.startButton.innerHTML = `${Utils.translate('wms.messages.start_picking')} ${selectedRows.length} ${Utils.translate('erp.order.names')}`
                        this.startButton.removeAttribute("disabled")
                    }
                }
            })
        }

    }
}